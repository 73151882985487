import React, { useRef } from 'react';
import { useAdjustUrlMapping } from '@/src/common/hooks/utmHooks';
import { bool, string } from 'prop-types';

const translations = {
  en: {
    enterFirstName: 'Enter your first name',
    enterLastName: 'Enter your last name',
    enterPhone: 'Enter your phone number',
    enterEmail: 'Enter your email',
    getStarted: 'Get started',
    sending: 'Sending...',
    sent: 'Sent!',
  },
  es: {
    enterFirstName: 'Tu nombre',
    enterLastName: 'Tu apellido',
    enterPhone: 'Tu numero telefonico',
    enterEmail: 'Tu correo electronico',
    getStarted: 'Empezar',
    sending: 'Enviando...',
    sent: 'Enviado!',
  },
};

const HeroBrazeForm = ({ brazeSource, dataGoal, lang, showPhoneInput }) => {
  const adjustMapping = useAdjustUrlMapping();
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const phoneRef = useRef(null);
  const emailRef = useRef(null);
  const submitRef = useRef(null);

  const translation = translations[lang] ?? translations.en;

  const setDisableFields = (disabled = true) => {
    emailRef.current.disabled = disabled;
    firstNameRef.current.disabled = disabled;
    lastNameRef.current.disabled = disabled;
    submitRef.current.disabled = disabled;

    if (showPhoneInput) {
      phoneRef.current.disabled = disabled;
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const params = {
      source: brazeSource,
      email: emailRef.current.value,
      firstName: firstNameRef.current.value,
      lastName: lastNameRef.current.value,
    };

    if (showPhoneInput) {
      params.phone = phoneRef.current.value;
    }

    window.analytics.identify({ ...params, ...adjustMapping });
    window.analytics.track(dataGoal);

    submitRef.current.value = translation.sending;

    setDisableFields();
    setTimeout(() => {
      setDisableFields(false);
      event.target.reset();
      submitRef.current.value = translation.sent;
    }, 1500);
  };

  return (
    <form onSubmit={onSubmit} className="form margin-top--sm">
      <div>
        <div className="grid grid--gap-sm">
          <div className="col--md-6">
            <input
              ref={firstNameRef}
              id="first_name"
              name="first_name"
              type="text"
              autoCapitalize="off"
              autoCorrect="off"
              defaultValue=""
              placeholder={translation.enterFirstName}
              className="margin-right--xs form-control form-control--md form-control--white text--left"
              required
            />
          </div>
          <div className="col--md-6">
            <input
              ref={lastNameRef}
              id="last_name"
              name="last_name"
              type="text"
              autoCapitalize="off"
              autoCorrect="off"
              defaultValue=""
              placeholder={translation.enterLastName}
              className="margin-right--xs form-control form-control--md form-control--white text--left margin-bottom--sm"
              required
            />
          </div>
        </div>

        {showPhoneInput && (
          <input
            ref={phoneRef}
            id="phone"
            name="phone"
            type="tel"
            autoCapitalize="off"
            autoCorrect="off"
            className="margin-right--xs form-control form-control--md form-control--white text--left margin-bottom--sm"
            placeholder={translation.enterPhone}
            required
          />
        )}

        <input
          ref={emailRef}
          id="email"
          name="email"
          type="email"
          autoCapitalize="off"
          autoCorrect="off"
          className="margin-right--xs form-control form-control--md form-control--white text--left margin-bottom--sm"
          placeholder={translation.enterEmail}
          required
        />

        <input
          ref={submitRef}
          type="submit"
          value={translation.getStarted}
          name="submit"
          className="btn btn--primary btn--md btn--fullwidth-sm"
        />
      </div>
    </form>
  );
};

HeroBrazeForm.defaultProps = {
  dataGoal: 'Form Submitted',
  lang: 'en',
  showPhoneInput: false,
};

HeroBrazeForm.propTypes = {
  brazeSource: string.isRequired,
  dataGoal: string,
  lang: string,
  showPhoneInput: bool,
};

export default HeroBrazeForm;
