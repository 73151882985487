import * as React from "react"

const ButtonCalendarPopup = ({ classname, titleCalendlyButton }) => (
  <button
    data-goal="Calendly Viewed"
    className={classname}
    onClick={() =>
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/d/k6dx-mdtf/joint-academy-physical-therapy",
        return: false,
      })
    }
  >
    {titleCalendlyButton}
  </button>
)

export default ButtonCalendarPopup
