import React from "react"

function FormHumanaGiftcard({ dataGoalGiftcard }) {
  const onSubmit = event => {
    if (typeof window !== "undefined") {
      window.MERGE0(event)
    }
  }

  return (
    <form
      id="trialForm"
      action="https://jointacademy.us14.list-manage.com/subscribe/post"
      method="post"
      className="validate form margin-top--sm"
      data-goal={dataGoalGiftcard}
      onSubmit={onSubmit}
    >
      <input type="hidden" name="u" value="af6b2530d423199a95fe20a13" />
      <input type="hidden" name="id" value="e19fee2865" />
      <div>
        <div className="grid grid--gap-sm">
          <div className="col--md-6">
            <input
              type="text"
              htmlFor="fname"
              autoCapitalize="off"
              autoCorrect="off"
              name="MERGE1"
              id="MERGE1"
              className="margin-right--xs form-control form-control--md form-control--white text--left"
              placeholder="Enter your first name"
              onFocus={e => (e.target.placeholder = "")}
              onBlur={e => (e.target.placeholder = "Enter your first name")}
              required
            />
          </div>
          <div className="col--md-6">
            <input
              type="text"
              htmlFor="lname"
              autoCapitalize="off"
              autoCorrect="off"
              name="MERGE2"
              id="MERGE2"
              className="margin-right--xs form-control form-control--md form-control--white text--left margin-bottom--sm"
              placeholder="Enter your last name"
              onFocus={e => (e.target.placeholder = "")}
              onBlur={e => (e.target.placeholder = "Enter your last name")}
              required
            />
          </div>
        </div>
        <input
          type="email"
          htmlFor="email"
          autoCapitalize="off"
          autoCorrect="off"
          name="MERGE0"
          id="MERGE0"
          className="margin-right--xs form-control form-control--md form-control--white text--left margin-bottom--sm"
          placeholder="Enter your email"
          onFocus={e => (e.target.placeholder = "")}
          onBlur={e => (e.target.placeholder = "Enter your email")}
          required
        />
        <input
          id="emailCampaign"
          type="submit"
          value="Get started"
          name="submit"
          className="btn btn--primary btn--md btn--fullwidth-sm"
        />
      </div>
      <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
        <input
          type="text"
          name="b_af6b2530d423199a95fe20a13_7c0cc7163d"
          tabIndex="-1"
        />
      </div>
    </form>
  )
}

export default FormHumanaGiftcard
