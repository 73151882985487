import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import HeroBrazeForm from '@components/atoms/HeroBrazeForm';
import SmsTag from '../../smsTag';
import Button from '../../Button';
import horizontal from '../../../assets/shape_border_horizontal.svg';
import vertical from '../../../assets/shape_border_vertical.svg';
import fsa from '../../../assets/fsa_hsa.svg';
import hipaa from '../../../assets/hipaa.svg';
import ssl from '../../../assets/ssl.svg';
import ButtonCalendarPopup from '../../buttonCalendarPopup';
import FormHumanaGiftcard from '../../FormHumanaGiftcard';

const HeroCTA = ({
  promo,
  smsTag,
  smsTagClassName,
  tagHeadline,
  dataTheme,
  imagedata,
  imagedata2,
  imagedata3,
  imagedata4,
  downloadButton,
  alt,
  topText,
  title,
  titleBack,
  titleOa,
  titleShoulders,
  titleClassName,
  text,
  textClassName,
  calldataGoal,
  callhref,
  callclassname,
  calltext,
  downloaddataGoal,
  downloadhref,
  downloadclassname,
  downloadtext,
  renderTitleButton,
  titleButtom,
  idCta,
  rederCalendly,
  classnameCalendly,
  imagedataNoCerts,
  titleCalendlyButton,
  dataToken,
  formHumanaGiftcard,
  titleButtomClassname,
  formSubtext,
  formSubtextClassname,
  dataGoalGiftcard,
  showHeroBrazeForm,
  heroBrazeFormSettings,
  lang,
  bottomText,
}) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "hero_referral.jpg" }) {
          childImageSharp {
              gatsbyImageData(
                  formats: [AUTO, WEBP],
                  placeholder: BLURRED
              )
          }
        }
        desktop2: file(relativePath: { eq: "hero2.jpg" }) {
          childImageSharp {
              gatsbyImageData(
                  formats: [AUTO, WEBP],
                  placeholder: BLURRED
              )
          }
        }
        desktop3: file(relativePath: { eq: "hero3.jpg" }) {
          childImageSharp {
              gatsbyImageData(
                  formats: [AUTO, WEBP],
                  placeholder: BLURRED
              )
          }
        }
        desktop4: file(relativePath: { eq: "hero_exercise1.jpeg" }) {
          childImageSharp {
              gatsbyImageData(
                  formats: [AUTO, WEBP],
                  placeholder: BLURRED
              )
          }
        }
      }
    `,
  );
  let backgroundImage = data.desktop;
  if (imagedata2) {
    backgroundImage = data.desktop2;
  } else if (imagedata3) {
    backgroundImage = data.desktop3;
  } else if (imagedata4 || imagedataNoCerts) {
    backgroundImage = data.desktop4;
  }

  const [DynamicTitle, setDynamicTitle] = useState(title);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.location.search.indexOf('?joint=back') > -1) {
        setDynamicTitle(titleBack);
      } else if (window.location.search.indexOf('?joint=oa') > -1) {
        setDynamicTitle(titleOa);
      } else if (window.location.search.indexOf('?joint=shoulders') > -1) {
        setDynamicTitle(titleShoulders);
      }
    }
  }, [setDynamicTitle, titleBack, titleOa, titleShoulders]);
  return (
    <section data-theme={dataTheme} id="top" style={{ overflow: 'visible' }}>
      <div className="container container--lg">
        <div className="grid flex--start">
          <div
            id="herotext"
            className="col col--12 col--md-6 margin-top--lg margin-bottom--lg"
          >
            {topText && (
              <p
                className="color--electric-blue margin-bottom--xs text--bold"
                style={{ fontWeight: '900', letterSpacing: '1px' }}
              >
                AVAILABLE AT NO COST TO YOU
              </p>
            )}

            <h1 className={`margin-bottom--sm ${titleClassName}`}>
              {DynamicTitle}
            </h1>
            <p className={textClassName}>{text}</p>
            <div>
              <div className="flex--center">
                {renderTitleButton && (
                  <p
                    className={`margin-bottom--sm text--sm ${titleButtomClassname}`}
                  >
                    {titleButtom}
                  </p>
                )}
                {calltext && (
                  <Button
                    idTrack={idCta}
                    dataGoal={calldataGoal}
                    href={callhref}
                    classname={callclassname}
                    text={calltext}
                  />
                )}
                {
                  rederCalendly && (
                    <ButtonCalendarPopup
                      classname={classnameCalendly}
                      titleCalendlyButton={titleCalendlyButton}
                    />
                  ) // button component to open calendar use rederCalendly
                }
                {downloadButton && (
                  <div className="hide--md">
                    <Button
                      dataGoal={downloaddataGoal}
                      href={downloadhref}
                      classname={downloadclassname}
                      text={downloadtext}
                    />
                  </div>
                )}
                {smsTag && (
                  <div className={`${smsTagClassName}`}>
                    <SmsTag dataToken={dataToken} tagHeadline={tagHeadline} />
                  </div>
                )}
                {showHeroBrazeForm && (
                  <HeroBrazeForm
                    brazeSource={heroBrazeFormSettings.brazeSource}
                    dataGoal={heroBrazeFormSettings?.dataGoal}
                    showPhoneInput={heroBrazeFormSettings?.showPhoneInput}
                    lang={lang}
                  />
                )}
                {formHumanaGiftcard && (
                  <FormHumanaGiftcard dataGoalGiftcard={dataGoalGiftcard} />
                )}
                {formSubtext && (
                  <p className={`text--xxs opacity--6 ${formSubtextClassname}`}>
                    {formSubtext}
                  </p>
                )}
                {bottomText && (
                  <p className="margin-top--sm">
                    {bottomText}
                  </p>
                )}
                {promo && (
                  <p className="margin-top--xs">
                    No health insurance?
                    <a
                      className="margin-left--xxs"
                      data-goal="Onboarding Viewed"
                      href="https://us-onboarding.jointacademy.com/"
                      style={{
                        textDecoration: 'underline',
                      }}
                    >
                      Try 7 days for free
                    </a>
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="hero--cover col col--12 col--sm-6 fullwidth--sm ">
            <img alt="horizont" className="sp-md hide--md" src={horizontal} />
            <img alt="vertical" className="sp-md display--md" src={vertical} />

            <GatsbyImage image={getImage(backgroundImage)} alt={alt || ''} />

            {!imagedataNoCerts && (
              <div className="certs flex flex--center-y">
                <img alt="fsa_hsa" src={fsa} />
                {' '}
                <img
                  alt="hipaa"
                  className="margin-left--xxs margin-right--xxs"
                  src={hipaa}
                />
                {' '}
                <img alt="ssl" src={ssl} />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroCTA;
